/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-17",
    versionChannel: "nightly",
    buildDate: "2024-11-17T00:06:50.213Z",
    commitHash: "114d24f73109ca6bbeb5784f81fa5b62988d154d",
};
